<template>
  <div>
    <div>
      <el-cascader class="el_input" style="width: 300px; margin-right: 10px; margin-bottom: 30px" v-model="case_cities" @change="ser_para_case_cities_change" placeholder="地址选择" :options="ser_para.cities" filterable></el-cascader>
    </div>
    <!-- <div class="center_pointer" v-if="center_pointer_show"><img src="https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png" alt=""></div>地图中心点 -->
    <div style="display: flex">
      <el-input
        style="width: 300px; margin-right: 10px; margin-bottom: 30px"
        v-model="inputLocation"
        @keyup.enter.native="search"
        @input="search"
        placeholder="请输入地址"
      ></el-input>
      <div class="btn" @click="handleFindLocation(inputLocation)">搜索</div>
      <div class="btn" @click="choose">确定</div>
      <div class="btn btn-free-select" @click="map_center" v-if="center_pointer_btn==1">自由选点</div>
			<div class="btn btn-free-select" @click="map_center_cancel" v-if="center_pointer_btn==2">搜索选点</div>
      <div class="addr_choose_list" v-if="addr_choose_list_is_show">
        <div
          class="addr_choose_ser_item"
          v-for="(item, index) in addr_choose_list"
          :key="index"
          @click="rel_to_map(item)"
        >
          <div class="addr_choose_ser_item_name">{{ item.name }}</div>
          <div class="addr_choose_ser_item_addr">
            {{ item.district }}{{ item.address }}
          </div>
        </div>
      </div>
    </div>

    <div id="map"></div>
  </div>
</template>
 
<script>
import { Loading } from "element-ui";
import {regionData,CodeToText,regionDataPlus} from 'element-china-area-data'
export default {
  	props:{
			old_data:Object,
      LngLat:Object
		},
  data() {
    return {
      map: {},
      inputLocation: "",
      localsearch: "",
      addr_choose_list: [],
      addr_choose_list_is_show: "",
      case_cities:'',
      ser_para:{
				cargo_type:[],//货物类型
					cities:regionDataPlus,//省市区数据
			},
      center_pointer_btn:1,//自由选点显示隐藏
      form:{
        prov:'',
        prov_code:'',
        city:'',
        city_code:'',
        county:'',
        county_code:'',
       
        addr_choose_obj:{
          name:'',
          location:{
            lng:'',
            lat:'',
          }
        }
      }
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    //自由选点
    map_center(){
      var that = this
      var geocode  = new T.Geocoder();
      this.center_pointer_btn =2
      function searchResult(result){
        that.map.clearOverLays()
        //向地图上添加标注
        let marker = new T.Marker(result.getLocationPoint(result.location.lat, result.location.lon));
        // console.log(marker)
        var markerInfoWin = new T.InfoWindow(result.formatted_address);
        that.map.addOverLay(marker);
        marker.addEventListener("click", function () {
            marker.openInfoWindow(markerInfoWin);
        });// 将标注添加到地图中
        //置入数据
        var data = result.addressComponent

        that.form.prov = data.province
        that.form.prov_code = data.province_code.slice(3)

        that.form.city = data.city
        that.form.city_code = data.city_code.slice(3)
        if(data.county == '仙桃市'){
           that.form.city = data.county
           that.form.city_code = data.county_code.slice(3)
        }
        that.form.county = data.county
        that.form.county_code = data.county_code.slice(3)

        that.form.addr_choose_obj.name = data.address

        that.form.addr_choose_obj.location.lng = result.location.lon
				that.form.addr_choose_obj.location.lat = result.location.lat

        that.inputLocation = result.formatted_address
      }
      this.map.addEventListener("click", function(e){
          // console.log(e.lnglat)
          geocode.getLocation(e.lnglat,searchResult);
        });
    },
    //搜索选点
    map_center_cancel(){
      //取消点击事件
     this.map.removeEventListener('click')
     //地图从新初始化
     this.load()
     //清空数据
     this.inputLocation = ''
     this.form={
        prov:'',
        prov_code:'',
        city:'',
        city_code:'',
        county:'',
        county_code:'',
       
        addr_choose_obj:{
          name:'',
          location:{
            lng:'',
            lat:'',
          }
        }
      }
    
     this.center_pointer_btn =1
    },
    //提交
    choose(){
        if(!this.form.addr_choose_obj.location.lng){
          this.$my.other.msg({
						type:"info",
						str:'请先选择地址'
					});
          return
        }
				this.$emit('choosed',{
					prov_name:this.form.prov,
					prov_code:this.form.prov_code,
					city_name:this.form.city,
					city_code:this.form.city_code,
					county_name:this.form.county,
					county_code:this.form.county_code,
					addr_info:this.form.addr_choose_obj.name,
					lng:this.form.addr_choose_obj.location.lng,
					lat:this.form.addr_choose_obj.location.lat,
				})
    },
    //地址选择
		ser_para_case_cities_change(event){
       	this.form.prov=CodeToText[event[0]];
        this.form.prov_code=event[0];
				this.form.city=CodeToText[event[1]];
        this.form.city_code=event[1];
       
				this.form.county=CodeToText[event[2]];
        this.form.county_code=event[2];
        this.handleFindLocation(CodeToText[event[0]] + CodeToText[event[1]] + CodeToText[event[2]]);
		},

    //列表选择点击
    rel_to_map(item) {
      this.addr_choose_list_is_show = false
      this.inputLocation = item.name
      this.handleFindLocation(item.address+item.name); 
    },
    //输入文字触发搜索建议
    search() {
      if (!this.inputLocation) {
        this.addr_choose_list = [];
        this.addr_choose_list_is_show = false;
        return;
      }
      this.localsearch.search(this.inputLocation, 4);
    },
    localSearchResult(result) {

      //解析建议词信息
      this.suggests(result.getSuggests());
    },
    suggests(obj) {
      if (obj) {
        this.addr_choose_list = obj;
        this.addr_choose_list_is_show = true;
      } else {
        this.addr_choose_list = [];
        this.addr_choose_list_is_show = false;
      }
    },
    //定位当前输入位置
    handleFindLocation(item) {
      //加载状态
      Loading.service(this.options);
      let geocoder = new T.Geocoder();
      let that = this;
      function searchResult(result) {
        if (result.getStatus() == 0) {
          //地图缩放
          that.map.panTo(result.getLocationPoint(), 16);
          //创建标注对象
          let marker = new T.Marker(result.getLocationPoint());
          var markerInfoWin = new T.InfoWindow(result.location.keyWord);
          if(that.center_pointer_btn ==1){
              //向地图上添加标注
              that.map.addOverLay(marker);
              marker.addEventListener("click", function () {
                  marker.openInfoWindow(markerInfoWin);
              });// 将标注添加到地图中
          }
          var addr =''
          //填了省市区 带上省市区一起拼接为详细地址
          if(that.form.prov){
            addr = that.form.prov +that.form.city+that.form.county+that.inputLocation
          }
         
          else if (result.location.keyWord.substr(0,2) == '中国'){
            var addr = result.location.keyWord.slice(2)
          }else{
             var addr = result.location.keyWord
          }
          //填写详细地址拆分 不填写详细地址不需要调用此接口
         if(that.inputLocation){
           //地址拆分
          that.$my.net.req({
            data:{
              mod:'res',
              ctr:'get_addr_info',
              addr
            },callback:(data)=>{
              if(!data.prov || item=="湖北省省直辖县级行政区划仙桃市"){
                  that.form.addr_choose_obj.name = addr
                  that.form.addr_choose_obj.location.lat = result.location.lat
                  that.form.addr_choose_obj.location.lng = result.location.lon
                  return
              }
              //置入地址
              that.form.prov = data.prov
              that.form.city = data.city
              that.form.county = data.county
              that.form.addr_choose_obj.name = data.detail
              that.form.addr_choose_obj.location.lat = result.location.lat
              that.form.addr_choose_obj.location.lng = result.location.lon
            }
          })
         }
          

        } else {
          
        }
        //关闭loading
        let loadingInstance = Loading.service(that.options);
        that.$nextTick(() => {
          loadingInstance.close();
        });
      }
      geocoder.getPoint(item, searchResult);
    },
    //地图初始化
    load() {
        if(this.old_data.addr){
          // this.handleFindLocation(this.old_data.addr)
          this.inputLocation = this.old_data.addr
        }
        // console.log(this.LngLat)
        // return
        //创建地图
        this.map = new T.Map("map");
        var lat
        var lng
        //没有经纬度 默认北京
        if(!this.LngLat.lng){
          lat = '116.40969'
          lng = '39.89945'
        }else{
          lat = this.LngLat.lng
          lng = this.LngLat.lat
          let marker = new T.Marker(new T.LngLat(lat,lng));
          // console.log(marker)
          this.map.addOverLay(marker);
        }
        this.map.centerAndZoom(new T.LngLat(lat,lng), 13);
        //关键字搜索使用
        var config = {
          pageCapacity: 10, //每页显示的数量
          onSearchComplete: this.localSearchResult, //接收数据的回调函数
        };
        //创建搜索对象
        this.localsearch = new T.LocalSearch(this.map, config);

        var lo = new T.Geolocation();
    },
  },
};
</script>
<style scoped>
#map {
  width: 100%;
  height: 550px;
}
.addr_choose_list {
  position: absolute;
  width: 433px;
  max-height: 500px;
  overflow-y: auto;
  top: 210px;
  left: 20px;
  box-shadow: 2px 2px 5px #777;
  background-color: #eee;
  z-index: 1000;
}
.addr_choose_ser_item {
  background-color: #fff;
  cursor: pointer;
  margin: 10px;
}
.addr_choose_ser_item:hover {
  background-color: #409eff;
  color: #fff;
}
.addr_choose_ser_item_name {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.addr_choose_ser_item_addr {
  padding: 10px 10px 15px 10px;
  color: #ccc;
}
.center_pointer {
  position: absolute;
  width: 0;
  height: 0;
  /* background-color: #409eff; */
  left: 50%;
  bottom: 50%;
  top: 50%;
  right: 50%;
  margin: auto;
  z-index: 10000;
}
.btn {
  margin-left: 7px;
  font-size: 14px;
  line-height: 38px;
  width: 60px;
  height: 38px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  background-color: #67c23a;
}
</style>